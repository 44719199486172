import { CurrencyAmount } from '@uniswap/sdk'
import { Pool } from 'data/Pool'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { TYPE, ExternalLink } from '../../theme'
import { computeFlashLoanFee } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'

const InfoLink = styled(ExternalLink)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.bg3};
  padding: 6px 6px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
`

function FlashLoanSummary({ currencyAmount }: { currencyAmount: CurrencyAmount }) {
  const theme = useContext(ThemeContext)
  const { realizedLPFee } = computeFlashLoanFee(currencyAmount)

  return (
    <>
      <AutoColumn style={{ padding: '0 16px' }}>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Liquidity Provider Fee
            </TYPE.black>
            <QuestionHelper text="A portion of each trade (0.05%) goes to liquidity providers as a protocol incentive." />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${currencyAmount.currency.symbol}` : '-'}
          </TYPE.black>
        </RowBetween>
      </AutoColumn>
    </>
  )
}

export interface AdvancedFlashLoanDetailsProps {
  pool?: Pool
  currencyAmount?: CurrencyAmount
}

export function AdvancedFlashLoanDetails({ pool, currencyAmount }: AdvancedFlashLoanDetailsProps) {

  return (
    <AutoColumn gap="0px">
      {pool && currencyAmount && (
        <>
          <FlashLoanSummary currencyAmount={currencyAmount} />
            <AutoColumn style={{ padding: '12px 16px 0 16px' }}>
              <InfoLink
                href={'https://flashloan-info.vercel.app/pool/' + pool.liquidityToken.address}
                target="_blank"
              >
                View pair analytics ↗
              </InfoLink>
            </AutoColumn>
        </>
      )}
    </AutoColumn>
  )
}
