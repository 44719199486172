import { createReducer } from '@reduxjs/toolkit'
import { Field, replaceFlashLoanState, selectCurrency, setRecipient, setData, typeInput } from './actions'

export interface SwapState {
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string
  readonly data: string | null
}

const initialState: SwapState = {
  typedValue: '',
  [Field.INPUT]: {
    currencyId: ''
  },
  recipient: '',
  data: null
}

export default createReducer<SwapState>(initialState, builder =>
  builder
    .addCase(
      replaceFlashLoanState,
      (state, { payload: { typedValue, recipient, data, inputCurrencyId } }) => {
        return {
          [Field.INPUT]: {
            currencyId: inputCurrencyId
          },
          typedValue: typedValue,
          recipient,
          data
        }
      }
    )
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      // the normal case
      return {
        ...state,
        [field]: { currencyId: currencyId }
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
    .addCase(setData, (state, { payload: { data } }) => {
      state.data = data
    })
)
