const INTRODUCTION = { title: 'Introduction', link: '/docs', tag: '', items: [] }

const PROTOCOL_OVERVIEW = {
  title: 'Protocol Overview',
  link: '',
  tag: '',
  items: [
    { title: 'How Deerfi works', link: '/docs/protocol-overview/how-deerfi-works' },
    { title: 'Ecosystem Participants', link: '/docs/protocol-overview/ecosystem-participants' },
    { title: 'Smart contracts', link: '/docs/protocol-overview/smart-contracts' },
    { title: 'Glossary', link: '/docs/protocol-overview/glossary' }
  ]
}

const CORE_CONCEPTS = {
  title: 'Core Concepts',
  link: '',
  tag: '',
  items: [{ title: 'Pools', link: '/docs/core-concepts/pools' }]
}

const ADVANCED_TOPICS = {
  title: 'Advanced Topics',
  link: '',
  tag: '',
  items: [
    { title: 'Fees', link: '/docs/advanced-topics/fees' },
    { title: 'Security', link: '/docs/advanced-topics/security' },
    { title: 'Math', link: '/docs/advanced-topics/math' }
  ]
}

const SMART_CONTRACT_INTEGRATION = {
  title: 'Smart Contract Integration',
  link: '',
  tag: 'DEVELOPER GUIDES',
  items: [
    { title: 'Implement Flash Loans', link: '/docs/smart-contract-integration/implement-flash-loans' },
    { title: 'Providing Liquidity', link: '/docs/smart-contract-integration/providing-liquidity' },
    { title: 'Pool Addresses', link: '/docs/smart-contract-integration/pool-addresses' },
    { title: 'Supporting meta transactions', link: '/docs/smart-contract-integration/supporting-meta-transactions' }
  ]
}

const INTERFACE_INTEGRATION = {
  title: 'Interface Integration',
  link: '',
  tag: '',
  items: [
    { title: 'Using the API', link: '/docs/interface-integration/using-the-api' },
    { title: 'Custom Linking', link: '/docs/interface-integration/custom-linking' }
  ]
}

const API = {
  title: 'API',
  link: '',
  tag: 'REFERENCE',
  items: [
    { title: 'API Overview', link: '/docs/api/overview' },
    { title: 'Entities', link: '/docs/api/entities' },
    { title: 'Queries', link: '/docs/api/queries' }
  ]
}

const SMART_CONTRACTS = {
  title: 'Smart Contracts',
  link: '',
  tag: '',
  items: [
    { title: 'Factory', link: '/docs/smart-contracts/factory' },
    { title: 'Pool', link: '/docs/smart-contracts/pool' },
    { title: 'Pool (ERC-20)', link: '/docs/smart-contracts/pool-erc-20' },
    { title: 'Library', link: '/docs/smart-contracts/library' },
    { title: 'Router', link: '/docs/smart-contracts/router' }
  ]
}

export const DOCUMENT_MENU_LIST = [
  INTRODUCTION,
  PROTOCOL_OVERVIEW,
  CORE_CONCEPTS,
  ADVANCED_TOPICS,
  SMART_CONTRACT_INTEGRATION,
  INTERFACE_INTEGRATION,
  API,
  SMART_CONTRACTS
]
