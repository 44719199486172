import React from 'react'
import { components } from 'components/mdx'
import INTRODUCTION from './00-introduction'
import PROTOCOL_OVERVIEW from './01-protocol-overview'
import CORE_CONCEPTS from './02-core-concepts'
import ADVANCED_TOPICS from './03-advanced-topics'
import SMART_CONTRACT_INTEGRATION from './04-smart-contract-integration'
import INTERFACE_INTEGRATION from './05-interface-integration'
import SMART_CONTRACTS from './06-smart-contracts'
import API from './07-API'

const DOC_CONTENT = {
  INTRODUCTION,
  PROTOCOL_OVERVIEW,
  CORE_CONCEPTS,
  ADVANCED_TOPICS,
  SMART_CONTRACT_INTEGRATION,
  INTERFACE_INTEGRATION,
  SMART_CONTRACTS,
  API
}

export function IntroductionContent() {
  const Content = DOC_CONTENT['INTRODUCTION']['introduction']
  return <Content components={components} />
}

export default function DocContent({ pathA, pathB }: { pathA: string; pathB: string }) {
  const parsedPath: string = pathA
    .split('-')
    .join('_')
    .toUpperCase()
  const Content = DOC_CONTENT.hasOwnProperty(parsedPath)
    ? DOC_CONTENT[parsedPath][pathB]
    : DOC_CONTENT['INTRODUCTION']['introduction']

  return <Content components={components} />
}
