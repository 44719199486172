import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import DropdownArrow from './dropdownArrow'
import { DOCUMENT_MENU_LIST } from '../../constants/doc'

const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: sticky;
  top: 2rem;
  align-self: flex-start;
  padding-right: 1rem;
  color: ${({ theme }) => theme.secondary1};
  @media (max-width: 960px) {
    top: 0px;
    position: relative;
    padding: 0rem;
    width: 100%;
    margin-bottom: 1rem;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledLink = styled(({ isActive, ...props }) => <Link {...props} />)`
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  border-radius: 8px;
  line-height: 1.55;
  text-decoration: none;
  margin: 0;
  color: ${({ theme }) => theme.secondary1};
  :hover {
    opacity: 0.6;
  }
`

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
`

const StyledListItem = styled.li`
  margin-bottom: 0.25rem;
  transition: transform 0.3s ease;
  will-change: transform;
  list-style: none;
  :hover {
    a {
      text-decoration: underline;
    }
  }
`

const StyledSectionTitle = styled.div<{ open?: boolean }>`
  margin: 0;
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: no-wrap;
  font-weight: ${({ open }) => (open ? 500 : 400)};
  font-size: 16px;
  color: ${({ theme }) => theme.secondary1};
`

const ListWrapper = styled.span`
  min-width: 200px;
  width: 224px;
  @media (max-width: 960px) {
    margin-bottom: 1rem;
  }
`

const SectionHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  margin: 1.2rem 0 0.5rem 0;
`

function CollapsibleList({ list }: { list: any }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledSectionTitle onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        {list.title}
        <div
          style={{
            marginLeft: '6px',
            opacity: '0.2',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        >
          <DropdownArrow />
        </div>
      </StyledSectionTitle>
      {open && (
        <div style={{ paddingLeft: '0.5rem' }}>
          {list.items.map((item: { link: any; title: any }, k: React.Key | undefined) => {
            return (
              <StyledListItem key={k}>
                <StyledLink to={item.link}>{item.title}</StyledLink>
              </StyledListItem>
            )
          })}
        </div>
      )}
    </>
  )
}

export default function DocSideBar() {
  return (
    <StyledSidebar>
      <ListWrapper>
        {DOCUMENT_MENU_LIST.map((item, i) => {
          return item.title === 'Introduction' ? (
            <StyledLink key={i} isActive={true} to={item.link}>
              {item.title}
            </StyledLink>
          ) : (
            <StyledList key={i} style={{ marginTop: item.items.length ? '0.6rem' : '0' }}>
              {item.tag && <SectionHeader>{item.tag}</SectionHeader>}
              {item.items.length ? (
                <CollapsibleList list={item} />
              ) : (
                <StyledSectionTitle>
                  <StyledLink to={item.link}>{item.title}</StyledLink>
                </StyledSectionTitle>
              )}
            </StyledList>
          )
        })}
      </ListWrapper>
    </StyledSidebar>
  )
}
