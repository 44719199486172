import { TokenAmount } from '@uniswap/sdk'
import React, { useCallback } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../TransactionConfirmationModal'
import FlashLoanModalFooter from './FlashLoanModalFooter'
import FlashLoanModalHeader from './FlashLoanModalHeader'

export default function ConfirmFlashLoanModal({
  tokenAmount,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash
}: {
  isOpen: boolean
  tokenAmount: TokenAmount | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  onConfirm: () => void
  swapErrorMessage: string | undefined
  onDismiss: () => void
}) {
  const modalHeader = useCallback(() => {
    return tokenAmount && recipient ? (
      <FlashLoanModalHeader
        tokenAmount={tokenAmount}
        recipient={recipient}
      />
    ) : null
  }, [recipient, tokenAmount])

  const modalBottom = useCallback(() => {
    return tokenAmount && recipient ? (
      <FlashLoanModalFooter
        onConfirm={onConfirm}
        currencyAmount={tokenAmount}
        disabledConfirm={false}
        swapErrorMessage={swapErrorMessage}
      />
    ) : null
  }, [onConfirm, swapErrorMessage, tokenAmount, recipient ])

  // text to show while loading
  const pendingText = `Flashing ${tokenAmount?.toSignificant(6)} ${
    tokenAmount?.currency?.symbol
  }`

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Swap"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
