import { lazy } from 'react'
import { importMDX } from 'mdx.macro'

const ADVANCED_TOPICS = {
  fees: lazy(() => importMDX('./01-fees.md')),
  security: lazy(() => importMDX('./02-security.md')),
  math: lazy(() => importMDX('./03-math.md'))
}

export default ADVANCED_TOPICS
