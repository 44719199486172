import React from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import twitter from '../../assets/svg/twitter.svg'
import discord from '../../assets/svg/discord.svg'
import telegram from '../../assets/svg/telegram.svg'
import medium from '../../assets/svg/medium.svg'
import styled from 'styled-components'

const BGCard = styled.span`
  width: 100vw;
  height: 100vh;
  max-height: 1220px;
  user-select: none;
  background-repeat: no-repeat;
  background: ${({ theme }) => theme.heroBG};
  background-size: contain;
  opacity: 0.2;
  @media (max-width: 960px) {
    width: 100vw;
    height: 100vh;
    max-height: 1220px;
  }
`

const StyledBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grey2};
  @media (max-width: 960px) {
    padding: 2rem;
  }
`

const Button = styled.a<{ outlined?: boolean; isHeader?: boolean }>`
  padding: 0.4rem 0.85rem;
  text-decoration: none;
  border-radius: 12px;
  display: inline-block;
  transform: scale(0.98);
  transition: transform 0.25s ease;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  width: fit-content;
  @media (max-width: 960px) {
    text-align: center;
    text-decoration: none;
    padding: 0.25rem 1rem;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding: ${({ isHeader }) => (isHeader ? '0.4rem 0.85rem' : '0.85rem 0.85rem')};
  }
  :hover {
    transform: scale(1);
  }
  background-color: ${({ outlined, theme }) => (outlined ? 'none' : theme.textColor)};
  color: ${({ outlined, theme }) => (outlined ? theme.textColor : theme.invertedTextColor)};
  border: ${({ outlined, theme }) => (outlined ? `1px solid ${theme.buttonBorder}` : 'initial')};
`

const StyledTitle = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  will-change: transform;
  margin: 3rem 0 4rem 0;
  margin-bottom: 12rem;
  @media (max-width: 960px) {
    margin: 3rem 0 1rem 0;
    margin-bottom: 4rem;
  }
  @media (max-width: 640px) {
    margin: 0 0 1rem 0;
    margin-bottom: 4rem;
  }
`

const StyledBodyTitle = styled.h1`
  font-size: 104px;
  margin: 4rem 0 3rem 0;
  pointer-events: none;
  white-space: wrap;
  overflow-wrap: normal;
  max-width: 900px;
  text-align: center;
  font-weight: 500;
  line-height: 1.1;
  @media (max-width: 1024px) {
    margin: 2rem 0 3rem 0;
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 2rem 0 2rem 0;
    font-weight: 500;
    text-align: left;
    font-size: 58px;
  }

  @media (max-width: 440px) {
    font-weight: 500;
    text-align: left;
    font-size: 52px;
  }
`

const StyledBodySubTitle = styled.h2`
  margin-bottom: 3rem;
  font-weight: 400;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const CardBGImage = styled.span<{ isDark: boolean }>`
  mix-blend-mode: ${({ isDark }) => (isDark ? 'overlay' : 'lighten')};
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-size: cover;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;

  top: 0;
  @media (max-width: 960px) {
    background-repeat: no-repeat;
  }
`

const CardNoise = styled.span`
  mix-blend-mode: overlay;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  z-index: 99;
`

const CardFade = styled.span`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.gradientBG};
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  z-index: 99;
  @media (max-width: 960px) {
    height: 125vh;
  }
`

const StyledBG = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.bg6};
  transform: translate3d(0, 0, 0);
  height: -webkit-fill-available;
  opacity: 1;
  background: ${({ theme }) => `radial-gradient(50% 50% at 50% 50%, ${theme.link} 0%, ${theme.bg6} 100%)`};
  opacity: 0.15;
  user-select: none;
  pointer-events: none;
`

const StyledSectionSubText = styled.h3`
  max-width: 430px;
  text-align: left;
  line-height: 160%;
  font-weight: 400;
`

const StyledBodySubText = styled.h3`
  max-width: 960px;
  text-align: center;
  line-height: 160%;
  font-weight: 400;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledBannerImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 260px;
  max-width: 720px;
  background-color: none;
  margin-top: 1rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.huge};
  @media (max-width: 960px) {
    min-width: unset;
  }
`

const StyledSectionTitle = styled.h1`
  font-size: 48px;
  white-space: wrap;
  overflow-wrap: normal;
  max-width: 900px;
  text-align: center;
  margin-top: 10rem;

  @media (max-width: 960px) {
    width: 100%;
    font-size: 2rem;
    line-height: 2.5rem;
    max-width: 600px;
    margin-top: 4rem;
  }
  @media (max-width: 640px) {
    width: 100%;
    font-weight: 400;
    margin-top: 4rem;
  }
`

const StyledItemRow = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0rem;
  width: 100%;
  & > *:not(:first-of-type) {
    margin-top: 12px;
  }
  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: center;
    & > * {
      margin-bottom: 12px;
    }
    & > *:not(:first-of-type) {
      margin-top: 0;
      margin-left: 12px;
    }
  }
  @media (min-width: 960px) {
    box-sizing: border-box;
    transition: right 0.25s ease;
  }
`

const StyledLink = styled(Link)`
  margin: 0;
  padding: 0;
  text-decoration: none;
  margin: 0.25rem 0;
  display: block;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  width: fit-content;
  color: ${({ theme }) => theme.red};
  transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);

  :hover {
    transform: translate3d(2px, 2px, 10px);
  }
  @media (max-width: 960px) {
    font-size: 20px;
  }
`

const ButtonLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 640px) {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

const StyledExternalLink = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: block;
  margin: 0.25rem 0;
  font-size: 24px;
  width: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  color: ${({ theme }) => theme.textColor};
  border-radius: 12px;

  @media (max-width: 960px) {
    font-size: 20px;
  }
`

const AppWrapper = styled.div`
  width: 240px;
  height: 270px;
  background: ${({ theme }) => theme.red};
  border-radius: 12px;
`

const IconWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  background: white;
  border-radius: 1rem;
  margin-top: 2rem;
`

const StyledProductImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-radius: 12px;
  padding: 20px;

  :hover {
    background: ${({ theme }) => theme.cardBG};
  }
`

const StyledProductImage = styled.img`
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
`

const StyledGridItemRow = styled.div`
  max-width: 860px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 40px;
  justify-items: center;
  margin: 10px auto 88px;
  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 10px auto 38px;
  }
`

const StyledCommunityTitle = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 20px;
`

export default function Home() {
  const isDark = useIsDarkMode()

  return (
    <div>
      <BGCard>
        <CardNoise />
        <CardBGImage isDark={isDark} />
        <CardFade />
      </BGCard>
      <StyledBody>
        <StyledTitle>
          <StyledBodyTitle>Deerfi Protocol Ecosystem</StyledBodyTitle>
          <StyledBodySubTitle>Make flash loans available to all Tokens for all users.</StyledBodySubTitle>
          <StyledItemRow>
            <ButtonLink to="/pool">
              <Button
                style={{
                  background: `linear-gradient(128.17deg, #BD00FF -14.78%, #FF1F8A 110.05%)`,
                  color: 'white',
                  fontSize: '20px'
                }}
              >
                Use FlashLoan
              </Button>
            </ButtonLink>
            <ButtonLink to="/lending">
              <Button
                style={{
                  background: `linear-gradient(128.17deg, #BD00FF -14.78%, #FF1F8A 110.05%)`,
                  color: 'white',
                  fontSize: '20px'
                }}
              >
                Use Lending
              </Button>
            </ButtonLink>
            <Button
              outlined={true}
              style={{
                fontSize: '20px'
              }}
              target="_blank"
              href="https://info.deerfi.com/"
            >
              Analytics
            </Button>
          </StyledItemRow>
        </StyledTitle>
        <FlashLoanSection />
        <LendingSection />
        <CommunitySection />
      </StyledBody>
      <StyledBG />
    </div>
  )
}

function FlashLoanSection() {
  return (
    <>
      <StyledSectionTitle>Deerfi FlashLoan</StyledSectionTitle>
      <StyledItemRow style={{ justifyContent: 'space-between' }}>
        <StyledSectionSubText>
          A flash loan is a feature that allows you to borrow any available amount of assets from a designated smart
          contract pool with no collateral.
          <StyledLink to="/pool">Use Flash Loan App</StyledLink>
        </StyledSectionSubText>
        <AppWrapper>
          <StyledItemRow>
            <IconWrapper>
              <StyledItemRow style={{ marginTop: '1rem' }}>
                <Text fontSize={'2.4rem'}>
                  <span role="img" aria-label="flashloan">
                    ⚡
                  </span>
                </Text>
              </StyledItemRow>
            </IconWrapper>
          </StyledItemRow>
          <StyledSectionSubText
            style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '2rem', color: 'white' }}
          >
            FlashLoan
          </StyledSectionSubText>
          <StyledItemRow>
            <ButtonLink to="/pool">
              <Button
                outlined={true}
                style={{
                  fontSize: '20px',
                  width: '12rem',
                  textAlign: 'center',
                  border: '2px solid white',
                  color: 'white'
                }}
              >
                Enter
              </Button>
            </ButtonLink>
          </StyledItemRow>
        </AppWrapper>
      </StyledItemRow>
      <StyledBannerImage />
    </>
  )
}

function LendingSection() {
  return (
    <>
      <StyledSectionTitle>Deerfi Lending</StyledSectionTitle>
      <StyledItemRow style={{ justifyContent: 'space-between' }}>
        <StyledSectionSubText>
          A simple, full featured dashboard, from the developers of Deerfi lending. Supply or borrow assets from the
          protocol.
          <StyledLink to="/lending">Use Lending App</StyledLink>
        </StyledSectionSubText>
        <AppWrapper>
          <StyledItemRow>
            <IconWrapper>
              <StyledItemRow style={{ marginTop: '1rem' }}>
                <Text fontSize={'2.4rem'}>
                  <span role="img" aria-label="lending">
                    💰
                  </span>
                </Text>
              </StyledItemRow>
            </IconWrapper>
          </StyledItemRow>
          <StyledSectionSubText
            style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '2rem', color: 'white' }}
          >
            Lending
          </StyledSectionSubText>
          <StyledItemRow>
            <ButtonLink to="/lending">
              <Button
                outlined={true}
                style={{
                  fontSize: '20px',
                  width: '12rem',
                  textAlign: 'center',
                  border: '2px solid white',
                  color: 'white'
                }}
              >
                Enter
              </Button>
            </ButtonLink>
          </StyledItemRow>
        </AppWrapper>
      </StyledItemRow>
      <StyledBannerImage />
    </>
  )
}

function CommunitySection() {
  return (
    <>
      <StyledSectionTitle>Global Community</StyledSectionTitle>
      <StyledBodySubText>
        Learn more about Deerfi, chat with the team, others in the community, and have your say in shaping the future of
        the Deerfi protocol.
      </StyledBodySubText>
      <StyledGridItemRow>
        <StyledExternalLink href={'https://discord.gg/SHdfFgX'} target="_blank">
          <StyledProductImageWrapper>
            <StyledProductImage src={discord} alt={'discord'} />
            <StyledCommunityTitle>Discord</StyledCommunityTitle>
          </StyledProductImageWrapper>
        </StyledExternalLink>
        <StyledExternalLink href={'https://twitter.com/deerfi_com'} target="_blank">
          <StyledProductImageWrapper>
            <StyledProductImage src={twitter} alt={'twitter'} />
            <StyledCommunityTitle>Twitter</StyledCommunityTitle>
          </StyledProductImageWrapper>
        </StyledExternalLink>
        <StyledExternalLink href={'https://medium.com/deerfi'} target="_blank">
          <StyledProductImageWrapper>
            <StyledProductImage src={medium} alt={'medium'} />
            <StyledCommunityTitle>Medium</StyledCommunityTitle>
          </StyledProductImageWrapper>
        </StyledExternalLink>
        <StyledExternalLink href={'https://t.me/deerfi'} target="_blank">
          <StyledProductImageWrapper>
            <StyledProductImage src={telegram} alt={'telegram'} />
            <StyledCommunityTitle>Telegram</StyledCommunityTitle>
          </StyledProductImageWrapper>
        </StyledExternalLink>
      </StyledGridItemRow>
    </>
  )
}
