import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { CustomLightSpinner } from '../../theme'
import DocSiderbar from './DocSiderbar'
import Circle from '../../assets/images/blue-loader.svg'
import { RouteComponentProps } from 'react-router-dom'
import DocContent, { IntroductionContent } from './md'

const StyledDocs = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr 180px;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 0px;
  }
`

const StyledMDX = styled.div`
  min-width: 550px;
  max-width: 768px;
  padding: 0;
  margin-bottom: 3rem;
  a {
    color: ${({ theme }) => theme.secondary1};
  }

  code {
    background-color: ${({ theme }) => theme.bg1};
    color: ${({ theme }) => theme.secondary1};
  }

  @media (max-width: 960px) {
    min-width: 100%;
    max-width: 100%;
  }
`

function LoadIcon() {
  return (
    <StyledMDX>
      <AutoColumn justify={'center'}>
        <CustomLightSpinner src={Circle} alt="loader" size={'30px'} />
      </AutoColumn>
    </StyledMDX>
  )
}

export function IntroductionDoc() {
  return (
    <StyledDocs>
      <DocSiderbar />
      <React.Suspense fallback={<LoadIcon />}>
        <StyledMDX>
          <IntroductionContent />
        </StyledMDX>
      </React.Suspense>
    </StyledDocs>
  )
}

export default function Doc({
  match: {
    params: { pathA, pathB }
  }
}: RouteComponentProps<{ pathA: string; pathB: string }>) {
  return (
    <StyledDocs>
      <DocSiderbar />
      <React.Suspense fallback={<LoadIcon />}>
        <StyledMDX>
          <DocContent pathA={pathA} pathB={pathB} />
        </StyledMDX>
      </React.Suspense>
    </StyledDocs>
  )
}
