import { CurrencyAmount, JSBI, Token, TokenAmount, Trade } from '@uniswap/sdk'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import ConfirmFlashLoanModal from '../../components/flashLoan/ConfirmFlashLoanModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { FlashLoanPoolTabs } from '../../components/NavigationTabs'
import { AutoRow } from '../../components/Row'
import AdvancedFlashLoanDetailsDropdown from '../../components/flashLoan/AdvancedFlashLoanDetailsDropdown'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TokenWarningModal from '../../components/TokenWarningModal'
import FlashLoanHeader from '../../components/flashLoan/FlashLoanHeader'

import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { useFlashLoanCallback } from '../../hooks/useSwapCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/flashLoan/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedFlashLoanInfo,
  useFlashLoanActionHandlers,
  useFlashLoanState
} from '../../state/flashLoan/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { useIsFlashLoanTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import DataInputPanel from 'components/DataInputPanel'

export default function FlashLoan() {
  const loadedUrlParams = useDefaultsFromURLSearch()

  // token warning stuff
  const loadedInputCurrency = useCurrency(loadedUrlParams?.inputCurrencyId)
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    })

  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // swap state
  const { typedValue, recipient, data } = useFlashLoanState()
  const {
    noEnoughLiquidity,
    pool,
    currencyBalance,
    parsedAmount,
    currency,
    inputError: swapInputError
  } = useDerivedFlashLoanInfo()

  const parsedToken = parsedAmount ? wrappedCurrency(parsedAmount.currency, chainId) : undefined
  const parsedTokenAmount = parsedAmount && parsedToken ? new TokenAmount(parsedToken, parsedAmount.raw) : undefined

  const { onCurrencySelection, onUserInput, onChangeRecipient, onChangeData } = useFlashLoanActionHandlers()
  const isValid = !swapInputError

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, flashLoanErrorMessage: swapErrorMessage, attemptingTxn, txHash }, setFlashLoanState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    flashLoanErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    flashLoanErrorMessage: undefined,
    txHash: undefined
  })

  const userHasSpecifiedInputOutput = Boolean(
    currency && parsedAmount?.greaterThan(JSBI.BigInt(0))
  )

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalance)
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: flashLoanCallback, error: flashLoanCallbackError } = useFlashLoanCallback(pool, parsedTokenAmount, recipient, data)

  const handleFlashLoan = useCallback(() => {
    if (!flashLoanCallback) {
      return
    }
    setFlashLoanState({ attemptingTxn: true, tradeToConfirm, showConfirm, flashLoanErrorMessage: undefined, txHash: undefined })
    flashLoanCallback()
      .then(hash => {
        setFlashLoanState({ attemptingTxn: false, tradeToConfirm, showConfirm, flashLoanErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'FlashLoan',
          action: 'FlashLoan',
          label: parsedAmount?.currency?.symbol
        })
      })
      .catch(error => {
        setFlashLoanState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          flashLoanErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [
    flashLoanCallback,
    parsedAmount,
    tradeToConfirm,
    showConfirm
  ])

  const handleConfirmDismiss = useCallback(() => {
    setFlashLoanState({ showConfirm: false, tradeToConfirm, attemptingTxn, flashLoanErrorMessage: swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const flashLoanIsUnsupported = useIsFlashLoanTransactionUnsupported(currency)

  return (
    <>
      <TokenWarningModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokens={importTokensNotInDefault}
        onConfirm={handleConfirmTokenWarning}
      />
      <FlashLoanPoolTabs active={'flashloan'} />
      <AppBody>
        <FlashLoanHeader />
        <Wrapper id="swap-page">
          <ConfirmFlashLoanModal
            isOpen={showConfirm}
            tokenAmount={parsedTokenAmount}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            onConfirm={handleFlashLoan}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={'md'}>
            <CurrencyInputPanel
              label={'From'}
              value={typedValue}
              showMaxButton={!atMaxAmountInput}
              currency={currency}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              id="swap-currency-input"
            />
            <AutoColumn justify="space-between">
              <AutoRow justify={'space-between'} style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable>
                  <ArrowDown size="16" color={theme.text2} />
                </ArrowWrapper>
                {data === null ? (
                  <LinkStyledButton id="add-recipient-button" onClick={() => onChangeData('')}>
                    + Add a data (optional)
                  </LinkStyledButton>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <>
              <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
            </>

            {data !== null ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-data-button" onClick={() => onChangeData(null)}>
                    - Remove data
                  </LinkStyledButton>
                </AutoRow>
                <DataInputPanel id="data" value={data} onChange={onChangeData} />
              </>
            ) : null}
          </AutoColumn>
          <BottomGrouping>
            {flashLoanIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : noEnoughLiquidity && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: 'center' }}>
                <TYPE.main mb="4px">Insufficient liquidity for this flash loan.</TYPE.main>
              </GreyCard>
            ) : (
              <ButtonError
                onClick={() => {
                  handleFlashLoan()
                }}
                id="swap-button"
                disabled={!isValid || !!flashLoanCallbackError}
                error={isValid && !flashLoanCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  {swapInputError
                    ? swapInputError
                    : `FlashLoan`}
                </Text>
              </ButtonError>
            )}
            {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
      {!flashLoanIsUnsupported ? (
        <AdvancedFlashLoanDetailsDropdown pool={pool} currencyAmount={parsedAmount} />
      ) : (
        <UnsupportedCurrencyFooter show={flashLoanIsUnsupported} currencies={[currency]} />
      )}
    </>
  )
}
