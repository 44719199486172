import { lazy } from 'react'
import { importMDX } from 'mdx.macro'

const SMART_CONTRACT_INTEGRATION = {
  'implement-flash-loans': lazy(() => importMDX('./01-implement-flash-loans.md')),
  'providing-liquidity': lazy(() => importMDX('./02-providing-liquidity.md')),
  'pool-addresses': lazy(() => importMDX('./03-pool-addresses.md')),
  'supporting-meta-transactions': lazy(() => importMDX('./04-supporting-meta-transactions.md'))
}

export default SMART_CONTRACT_INTEGRATION
