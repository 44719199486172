import { lazy } from 'react'
import { importMDX } from 'mdx.macro'

const PROTOCOL_OVERVIEW = {
  'how-deerfi-works': lazy(() => importMDX('./01-how-deerfi-works.md')),
  'ecosystem-participants': lazy(() => importMDX('./02-ecosystem-participants.md')),
  'smart-contracts': lazy(() => importMDX('./03-smart-contracts.md')),
  glossary: lazy(() => importMDX('./04-glossary.md'))
}

export default PROTOCOL_OVERVIEW
