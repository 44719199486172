import Info from './info'
import Title from './title'
import InlineCode from './inlineCode'
import Wizard from './wizard'
import InlineBoxLink from './inlineBoxLink'
import Code from './code'
import styled from 'styled-components'
import { Link as reactLink } from 'react-router-dom'

export const Link = styled(reactLink)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.secondary1};
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const styledA = styled.a`
  color: ${({ theme }) => theme.secondary1};
  text-decoration: none;
`

const styledH1 = styled.h1`
  font-family: 'GT Haptik Regular';
  margin-top: 1.75rem;
  font-size: 1.875rem;
  font-weight: 500;
`

const styledH2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 1.45rem;
  text-rendering: optimizeLegibility;
  line-height: 1.1;
  font-family: 'GT Haptik Regular';
`

const styledP = styled.p`
  line-height: 1.55;
  margin: 0 0 1.45rem;
`

const styledUl = styled.ul`
  padding: 0;
  margin: 0 0 1.45rem 1.45rem;
  list-style-position: outside;
  list-style-image: none;
`

const styledOl = styled.ol`
  padding: 0;
  margin: 0 0 1.45rem 1.45rem;
  list-style-position: outside;
  list-style-image: none;
`

const styledLi = styled.li`
  padding-left: 0;
  margin-bottom: 0.725rem;
`

const styledTable = styled.table`
  font-size: 1rem;
  line-height: 1.45rem;
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-feature-settings: 'tnum';
    padding: 0.725rem 0.96667rem calc(0.725rem - 1px);
  }
`

export const components = {
  a: styledA,
  h1: styledH1,
  h2: styledH2,
  p: styledP,
  ul: styledUl,
  ol: styledOl,
  li: styledLi,
  table: styledTable,
  code: Code,
  info: Info,
  title: Title,
  Wizard,
  inlineBoxLink: InlineBoxLink,
  inlineCode: InlineCode,
  Link
}
