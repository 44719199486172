import React from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components'

const StyledCode = styled.code`
  color: inherit !important;
  cursor: inherit !important;
  line-height: 1.4;
  border-radius: 3px;
  padding: 0.2em 0.3em;
  font-size: 85%;
  font-family: Consolas, Liberation Mono, Menlo, Courier, monospace;
`

export default function InlineCode({ children }: { children: React.ReactNode }) {
  const [isDark] = useDarkModeManager()
  return <StyledCode style={{ backgroundColor: isDark ? 'hsla(0,0%,96.9%,.13)' : 'inherit' }}>{children}</StyledCode>
}
