import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT'
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('flashLoan/selectCurrency')
export const typeInput = createAction<{ field: Field; typedValue: string }>('flashLoan/typeInput')
export const replaceFlashLoanState = createAction<{
  typedValue: string
  inputCurrencyId?: string
  recipient: string
  data: string | null
}>('flashLoan/replaceFlashLoanState')
export const setRecipient = createAction<{ recipient: string }>('flashLoan/setRecipient')
export const setData = createAction<{ data: string | null }>('flashLoan/setData')
