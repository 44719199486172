import { lazy } from 'react'
import { importMDX } from 'mdx.macro'

const API = {
  overview: lazy(() => importMDX('./01-overview.md')),
  entities: lazy(() => importMDX('./02-entities.md')),
  queries: lazy(() => importMDX('./03-queries.md'))
}

export default API
