import { lazy } from 'react'
import { importMDX } from 'mdx.macro'

const SMART_CONTRACTS = {
  factory: lazy(() => importMDX('./01-factory.md')),
  pool: lazy(() => importMDX('./02-pool.md')),
  'pool-erc-20': lazy(() => importMDX('./03-pool-erc-20.md')),
  library: lazy(() => importMDX('./04-library.md')),
  router: lazy(() => importMDX('./05-router.md'))
}

export default SMART_CONTRACTS
